import LayOut from "@components/layout";
import Hero from "@components/shared/hero";
import SEO from "@components/shared/seo";
import { Query, SanityDownloadPageConnection } from "@graphql-types";
import { useRegionChanged } from "@util/hooks";
import { Container, GridContainer, MenuButtonGatsbyLink } from "@util/standard";
import { PageProps, graphql } from "gatsby";
import React, { useEffect } from "react";

interface Data extends Query {
  altRegions: SanityDownloadPageConnection;
}

interface Props extends PageProps {
  data: Data;
  pageContext: any;
}

const Downloads = (props: Props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useRegionChanged("downloads");

  const sanityDownloadPage = props.data.allSanityDownloadPage.nodes[0];
  const alternateRegions = props.data.altRegions.nodes.map(
    alt => alt._rawRegion
  );

  return (
    <>
      <SEO
        seoData={sanityDownloadPage.seo}
        alternateRegions={alternateRegions}
        slug={props.pageContext.slug}
      />

      <LayOut>
        <Hero singleHeroContent={sanityDownloadPage.hero} />
        <Container
          width="100%"
          backgroundColor="babyBlue"
          margin="40px auto auto auto"
        >
          <GridContainer
            gridTemplateColumns="1fr 1fr"
            width="80%"
            margin="auto"
            gridGap="40px 80px"
            padding="70px 0px"
            mobileRepeat={1}
          >
            {sanityDownloadPage?.downloads?.map(download => (
              <Container
                key={download?._key}
                backgroundColor="white"
                padding="40px 25px"
                height="auto"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <h3>{download?.title}</h3>
                <p>{download?.description}</p>

                {download?.downloadLink && (
                  <MenuButtonGatsbyLink
                    width="fit-content"
                    margin="0px"
                    padding="10px 25px 10px 25px"
                    color="secondary"
                    href={`${download?.downloadLink?.asset?.url}?dl`}
                  >
                    Download
                  </MenuButtonGatsbyLink>
                )}
              </Container>
            ))}
          </GridContainer>
        </Container>
      </LayOut>
    </>
  );
};

export default Downloads;

export const query = graphql`
  query downloadQuery($iban: String) {
    allSanityDownloadPage(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        hero {
          ...sanityHero
        }
        seo {
          ...sanitySeo
        }
        downloads {
          _key
          title
          description
          downloadLink {
            asset {
              originalFilename
              source {
                url
              }
              path
              url
            }
          }
          link {
            internallink
            linktext
            newwindow
            url
          }
        }
      }
    }
    altRegions: allSanityDownloadPage {
      nodes {
        _rawRegion(resolveReferences: { maxDepth: 10 })
      }
    }
  }
`;
